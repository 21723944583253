import { ThemeProvider } from "@material-ui/core/styles";
import React, { Component, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Transition } from "react-transition-group";
import styled from "styled-components";

import { presentations as presentationsApi } from "apis/callables";
import { PresentationActivityType, MetricName } from "common/interfaces";
import { PositionType } from "legacy-common/constants";
import { getCanvasBundle } from "legacy-js/canvas";
import AppController from "legacy-js/core/AppController";
import getLogger, { LogGroup } from "js/core/logger";
import { ds } from "js/core/models/dataService";
import { PresentationNotFoundError, PresentationPermissionDeniedError, getPresentation } from "js/core/models/presentation";
import * as geom from "js/core/utilities/geom";
import { Key } from "js/core/utilities/keys";
import { FailedToRenderCanvasError } from "legacy-js/editor/PresentationEditor/CanvasController";
import PresentationEditorController, { PanelType } from "legacy-js/editor/PresentationEditor/PresentationEditorController";
import { TabKeyController } from "legacy-js/editor/PresentationEditor/TabKeyController";
import { app } from "js/namespaces";
import CollaborationBar from "legacy-js/react/components/CollaborationBar";
import { UpdateBanner } from "legacy-js/react/components/UpdateBanner";
import { ShowDialog, ShowErrorDialog, ShowWarningDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import Spinner from "legacy-js/react/components/Spinner";
import { dialogTheme } from "legacy-js/react/materialThemeOverrides";
import AnimationPanel from "legacy-js/react/views/AnimationPanel/AnimationPanel";
import { EditorCommentsPane } from "legacy-js/react/views/CommentsPane/EditorCommentsPane";
import RecordPanel from "legacy-js/react/views/RecordPanel/RecordPanel";
import RequestAccessDialog, { RequestAccessDialogContextType } from "legacy-js/react/views/RequestAccessDialog/RequestAccessDialog";
import { $, _ } from "legacy-js/vendor";
import { isPPTAddin } from "legacy-js/config";
import { trackActivity } from "js/core/utilities/utilities";
import { getExperiments } from "js/core/services/experiments";
import PresentationSettingsContainer from "legacy-js/react/views/PresentationSettings/PresentationSettingsContainer";
import PresentationLibraryController from "legacy-js/controllers/PresentationLibraryController";
import { RemoveSplashScreen, RestoreSplashScreen } from "js/core/SplashScreen";

import { ClipboardController } from "./ClipboardController";
import { AddElementDropZone } from "./Components/AddElementDropZone";
import AddElementPanel from "./Components/AddElementPanel";
import { CanvasWrapper } from "./Components/CanvasWrapper";
import ColorPanel from "./Components/ColorPanel";
import { BottomPanel, LeftSidePanel, RightSidePanel } from "./Components/EditorPanels";
import LayoutPanel from "./Components/LayoutPanel";
import PresentationMenuBar from "./Components/PresentationMenuBar";
import PresentationSideMenuBar from "./Components/PresentationSideMenuBar";
import { RevisionPanel } from "./Components/RevisionPanel";
import SharedSlideEditorMenuBar from "./Components/SharedSlideEditorMenuBar";
import ShortcutPanel from "./Components/ShortCutPanel";
import SlideActions from "./Components/SlideActions";
import SlideGridWrapper from "./Components/SlideGridWrapper";
import SpeakerNotes from "./Components/SpeakerNotes";
import VariationsPanel from "./Components/VariationsPanel";

const logger = getLogger(LogGroup.EDITOR);

const SECONDARY_CANVAS_SCALE = 0.5;
const SECONDARY_SLIDE_OPACITY = 0.2;
const SLIDE_GAP = 100;

const SLIDE_TRANSITION_DURATION_MS = 350;
const TRANSITION = `${SLIDE_TRANSITION_DURATION_MS}ms ease`;

const COMMENT_PANEL_SIZE = 250;

const CONTROLS_HEIGHT = 70;
const BOTTOM_CANVAS_MARGIN = 30;

const SELECTION_LAYER_TRANSITION_DURATION_MS = 300;

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: #4b4e55;
  pointer-events: auto;
  display: flex;
  flex-direction: column;
`;

const InnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

const EditorContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const CanvasContainer = styled.div`
  width: 100%;
  height: 100%;
  pointer-events: auto;
  position: absolute;
  margin-top: 20px;
`;

const RightCanvasControls = styled.div`
  position: absolute;
  width: 100px;
  height: ${props => props.canvasBounds.height}px;
  transform: ${props => `translateX(${props.canvasBounds.right}px) translateY(${props.canvasBounds.top}px)}`};
  opacity: ${props => props.visible ? 1 : 0};
  transition: ${props => props.transition ? TRANSITION : "none"};
  margin-top: 20px;

  .MuiIcon-root {
    color: white;
  }
`;

const ElementPanelContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const SlideGridContainer = styled.div`
  background: #4b4e55;
  width: 100%;
  height: 100%;
  position: absolute;
  transition: opacity 500ms;
  opacity: ${({ state }) => (["entering", "entered"].includes(state) ? 1 : 0)};
`;

const PositionedCanvas = styled.div.attrs(({ layout, showTransition }) => ({
    style: {
        transform: `translateX(${layout.x}px) translateY(${layout.y}px) scale(${layout.scale}`,
        transition: showTransition ? `opacity 350ms, transform ${TRANSITION}` : "none"
    }
}))`
  position: absolute;
  transform-origin: 0 0;
  pointer-events: auto;
`;

const SelectionLayerFrame = styled.div.attrs(({ canvasBounds, visible }) => ({
    style: {
        opacity: visible ? 1 : 0,
        width: canvasBounds.width,
        height: canvasBounds.height,
        top: canvasBounds.top,
        left: canvasBounds.left,
        transition: `opacity ${visible ? SELECTION_LAYER_TRANSITION_DURATION_MS : 0}ms`
    }
}))`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  pointer-events: none;

  #selection_layer {
    width: 100%;
    height: 100%;
  }
`;

const PresentationEditor = AppController.withState(
    PresentationEditorController.withState(
        class PresentationEditor extends Component {
            constructor() {
                super();

                this.canvasContainerRef = React.createRef();
                this.editorContainerRef = React.createRef();
                this.selectionLayerRef = React.createRef();
                this.elementPanelContainerRef = React.createRef();

                this.renderSelectionLayerPromiseChain = Promise.resolve();
                this.handleCurrentSlideChangePromiseChain = Promise.resolve();

                this.canvasWrappers = {};

                this.state = {
                    canvasWidth: 1280,
                    canvasHeight: 720,
                    slideLayoutProps: [],
                    isCurrentCanvasRendered: false,
                    canvasPadding: 140,
                    isDragging: false,
                    isReady: false,
                    showUpdateBanner: sessionStorage.getItem("hideUpdateBanner") !== "true"
                };

                this.throttledWheelEvent = _.throttle(
                    event => {
                        const { activePanel, showSlideGrid, showEditorPopup, slides, externalActivePanel } = this.props;

                        const hasOpenDialogs = app.dialogManager.openDialogs.length > 0;
                        const isPopupOpen = PresentationEditorController.getPopupOpenState();
                        const preventScrolling = activePanel || showSlideGrid || showEditorPopup || externalActivePanel;

                        if (hasOpenDialogs || isPopupOpen || preventScrolling) {
                            this.isScrolling = false;
                            return;
                        }

                        const direction = Math.sign(event.originalEvent.deltaY);
                        if (direction < 0) {
                            if (PresentationEditorController.getCurrentSlideIndex() === 0) {
                                this.isScrolling = false;
                                return;
                            }

                            this.isScrolling = true;
                            PresentationEditorController.goPrevSlide();
                        } else if (direction > 0) {
                            if (PresentationEditorController.getCurrentSlideIndex() === slides.length - 1) {
                                this.isScrolling = false;
                                return;
                            }
                            this.isScrolling = true;
                            PresentationEditorController.goNextSlide();
                        }
                    },
                    250,
                    { leading: true, trailing: false }
                );

                this.filteredWheelEvent = event => {
                    if (this.isScrolling) {
                        return;
                    }
                    const isAncestorPresentationEditor = elem => {
                        return (
                            !!elem &&
                            (
                                elem.id === "presentation-editor-container" ||
                                isAncestorPresentationEditor(elem.parentNode)
                            )
                        );
                    };
                    if (!isAncestorPresentationEditor(event.target)) {
                        return;
                    }

                    // Magic Mouse generates a lot of low value deltas at even the smallest
                    //   gesture. MX Master mouse's smallest delta is 4.000244140625. So we
                    //   skip anything lower than 4 to verify intent to scroll.
                    const delta = Math.abs(event.originalEvent.deltaY);
                    if (delta < 5) {
                        return;
                    }

                    this.throttledWheelEvent(event);
                };
            }

            async componentDidMount() {
                logger.info("[PresentationEditor] mounted");

                const { onLoaded, showSelectionLayer, presentation } = this.props;

                // Event handlers
                $(window).on("keydown.editor", this.handleKeyDown);
                $(window).on("resize.editor", this.handleResize);
                $(window).on("paste.editor", ClipboardController.onPaste);
                $(window).on("contextmenu.editor", this.handleContextMenu);

                $(window).on("wheel.editor", this.filteredWheelEvent);

                await this.setStateAsync({ isReady: true });

                await this.calculateSlideLayout(false);

                if (showSelectionLayer) {
                    await this.renderSelectionLayer();
                }

                this.recordOpenCloseActivity("open");

                const {
                    template_recommendations: { enabled: hasTemplateRecommendations }
                } = await getExperiments(["template_recommendations"]);
                const props = {
                    source_presentation_id: presentation.get("sourcePresentationId") || "",
                    source_presentation_name: presentation.get("sourcePresentationName") || "",
                    is_from_recommended_template: presentation.get("metadata")?.isFromRecommendedTemplate ?? false,
                    object: "library_item",
                    object_label: "",
                    action: "clicked",
                    experiment_id: "5FC18A79E182FE7C764425D4F852F501",
                    experiment_group_assignment: hasTemplateRecommendations ? "variant-a" : "control"
                };

                trackActivity("Presentation", "Open", presentation.id, null, props, { audit: true });

                if (onLoaded) {
                    onLoaded();
                }
            }

            componentWillUnmount() {
                logger.info("[PresentationEditor] unmounting");

                this.recordOpenCloseActivity("close");

                $(window).off(".editor");

                this.clearSelectionLayer();
            }

            async componentDidUpdate(prevProps, prevState, snapshot) {
                const {
                    currentSlide,
                    showSelectionLayer
                } = this.props;

                if (prevProps.currentSlide !== currentSlide) {
                    this.handleCurrentSlideChange();
                    return;
                }

                if (prevProps.activePanel !== this.props.activePanel || prevProps.showComments !== this.props.showComments) {
                    this.calculateSlideLayout(true);
                    return;
                }

                if (prevProps.slides !== this.props.slides) {
                    this.calculateSlideLayout(false);
                    return;
                }

                if (prevProps.showSelectionLayer !== showSelectionLayer) {
                    if (showSelectionLayer) {
                        this.renderSelectionLayer();
                    } else {
                        this.clearSelectionLayer();
                    }
                }
            }

            handleCurrentSlideChange() {
                // Keeping the current slide id to keep track of its changes
                const currentSlideId = this.props.currentSlide.id;

                // Keep track of the current slide change request for metrics
                this.currentSlideChangeRequest = {
                    slideId: currentSlideId,
                    timestamp: Date.now()
                };

                return new Promise((resolve, reject) => {
                    this.handleCurrentSlideChangePromiseChain = this.handleCurrentSlideChangePromiseChain
                        .then(async () => {
                            if (this.props.currentSlide.id !== currentSlideId) {
                                // Current slide changed while we were waiting for the prev call
                                return;
                            }

                            await Promise.all([
                                PresentationEditorController.hideCanvasControls(),
                                this.calculateSlideLayout(true)
                            ]);

                            await new Promise(resolve => setTimeout(resolve, SLIDE_TRANSITION_DURATION_MS));

                            const { currentSlide, showSelectionLayer, activePanel } = this.props;

                            if (currentSlideId !== currentSlide.id) {
                                // Current slide changed while we were transitioning
                                return;
                            }

                            if (showSelectionLayer) {
                                await this.renderSelectionLayer();
                            } else {
                                await this.clearSelectionLayer();
                            }

                            if (!activePanel) {
                                await PresentationEditorController.showCanvasControls();
                            }
                        })
                        .then(resolve)
                        .catch(reject);
                });
            }

            setStateAsync(stateUpdate) {
                return new Promise(resolve => this.setState(stateUpdate, resolve));
            }

            recordOpenCloseActivity = async action => {
                const { presentation } = this.props;

                if (!this.state.isReady || this.props.isSingleSlideEditor || isPPTAddin) return;

                try {
                    const {
                        template_recommendations: { enabled: hasTemplateRecommendations }
                    } = await getExperiments(["template_recommendations"]);

                    await presentationsApi.recordActivity({
                        id: presentation.id,
                        activity: action === "open" ? PresentationActivityType.EDITOR_OPENED : PresentationActivityType.EDITOR_CLOSED,
                        userId: app.user.id,
                        activityData: action === "open" ? {
                            source_presentation_id: presentation.get("sourcePresentationId") || "",
                            source_presentation_name: presentation.get("sourcePresentationName") || "",
                            is_from_recommended_template: presentation.get("metadata")?.isFromRecommendedTemplate ?? false,
                            object: "library_item",
                            object_label: "",
                            action: "clicked",
                            experiment_id: "5FC18A79E182FE7C764425D4F852F501",
                            experiment_group_assignment: hasTemplateRecommendations ? "variant-a" : "control"
                        } : null
                    });
                } catch (error) {
                    logger.error(`[PresentationEditor] failed to record activity: ${error}`);
                }
            }

            trackSlideNavigation = ({ method }) => {
                const { presentation } = this.props;
                const deviceType = app.isMobileOrTablet ? "mobile" : "desktop";

                return trackActivity("Editor", "Navigated", null, null, {
                    presentation_id: presentation.id,
                    method: method,
                    deviceType: deviceType,
                    ownerId: presentation.userId,
                    userId: app.user.id,
                    source: "editor",
                    referrer: (document.referrer && !document.referrer.includes(location.host)) ? document.referrer : "direct",
                });
            }

            handleKeyDown = event => {
                const { presentation, currentCanvasController, currentSelectionLayer, showSlideGrid, isSingleSlideEditor, handleKeyEvents } = this.props;

                if (!handleKeyEvents) {
                    return;
                }

                if (event.target.nodeName !== "BODY" && !isSingleSlideEditor) {
                    return;
                }

                if (showSlideGrid) {
                    return;
                }

                switch (event.which) {
                    case Key.DELETE:
                    case Key.BACKSPACE:
                        if (ds.selection.element) {
                            ds.selection.element.getSelectionElement().overlay?.handleKeyboardShortcut(event);
                        } else {
                            currentCanvasController.canvas.layouter.elements.primary.getSelectionElement().overlay?.handleKeyboardShortcut(event);
                        }
                        event.preventDefault();
                        return;
                    case Key.LEFT_ARROW:
                    case Key.UP_ARROW:
                    case Key.PAGE_UP:
                        if (!currentSelectionLayer?.preventSlideNavigationOnArrowKeys) {
                            PresentationEditorController.goPrevSlide();
                            this.trackSlideNavigation({ method: "tab" });
                        }
                        return;
                    case Key.RIGHT_ARROW:
                    case Key.DOWN_ARROW:
                    case Key.PAGE_DOWN:
                        if (!currentSelectionLayer?.preventSlideNavigationOnArrowKeys) {
                            PresentationEditorController.goNextSlide();
                            this.trackSlideNavigation({ method: "tab" });
                        }
                        return;
                    case Key.HOME:
                        this.goToSlide(0);
                        return;
                    case Key.END:
                        this.goToSlide(presentation.slides.length - 1);
                        return;
                    case Key.SPACE:
                        PresentationEditorController.toggleSlideGrid();
                        return;
                    case Key.TAB:
                        TabKeyController.handleTabKey(event);
                        return;
                }

                if (event.metaKey || event.ctrlKey) {
                    switch (event.which) {
                        case Key.KEY_Z:
                            if (event.shiftKey) {
                                PresentationEditorController.redo();
                            } else {
                                PresentationEditorController.undo();
                            }
                            event.stopPropagation();
                            event.preventDefault();
                            return;
                        case Key.KEY_E:
                            if (event.metaKey || event.ctrlKey && event.altKey) {
                                app.themeManager.loadTheme(presentation)
                                    .then(() => currentCanvasController.canvas.loadStyles(true))
                                    .then(() => {
                                        ds.selection.element = null;
                                        currentCanvasController.canvas.getCanvasElement().markStylesAsDirty();
                                        currentCanvasController.canvas.clearAuthoringBlockPropsCache();
                                        currentCanvasController.canvas.renderModel();
                                    });
                            }
                            break;
                        case Key.KEY_N:
                            PresentationEditorController.showAddSlideDialog();
                            event.preventDefault();
                            return;
                        case Key.KEY_D:
                            currentCanvasController.canvas.layouter.elements.primary.getSelectionElement().overlay?.handleKeyboardShortcut(event);
                            event.preventDefault();
                            return;
                        case Key.KEY_C:
                        case Key.KEY_X:
                            ClipboardController.onCopyOrCut(event);
                            return;
                    }
                }
            }

            handleResize = () => {
                const { showSelectionLayer } = this.props;

                this.calculateSlideLayout(false)
                    .then(() => {
                        if (showSelectionLayer) {
                            this.renderSelectionLayer();
                        }
                    });
            }

            handleContextMenu = event => {
                // don't prevent contextMenu on contentEditables
                if ($(event.target).closest("[contenteditable = true]").length) return;

                if (app.isEditingText) return;

                event.preventDefault();
                event.stopPropagation();
            }

            goToSlide = index => PresentationEditorController.setCurrentSlideByIndex(index)

            removeFileDropOverlay = $editorContainer => {
                $editorContainer.off("dragover drop dragenter dragleave");
            }

            renderFileDropOverlay = $editorContainer => {
                const { activePanel } = this.props;

                const { activePanelProps } = this.state;

                $editorContainer.fileDrop({
                    proxy: true,
                    over: async e => {
                        if (activePanel && activePanelProps && !activePanelProps.showControls) {
                            // NOTE: closeAllPanels() triggers selectionLayer being
                            //   reset on a later frame, so we wait for that change
                            await PresentationEditorController.closeAllPanels();
                            await this.renderSelectionLayerPromiseChain;
                        }
                        this.selectionLayer.onDragOver(e);
                    },
                    drop: e => this.selectionLayer.onDrop(e)
                });
            }

            calculateSlideLayout = async transition => {
                const { slides, currentSlide, activePanel, canvasControllers, isSingleSlideEditor } = this.props;
                const { canvasWidth, canvasHeight, activePanelProps } = this.state;
                const prevSlideLayoutProps = this.state.slideLayoutProps;

                let canvasPaddingLeft, canvasPaddingRight;

                if (isSingleSlideEditor) {
                    canvasPaddingLeft = 30;
                    canvasPaddingRight = 30;
                } else {
                    canvasPaddingLeft = 140;
                    canvasPaddingRight = 140;
                    if (window.innerWidth < 1400) {
                        canvasPaddingLeft = window.innerWidth * .02;
                        canvasPaddingRight = Math.max(window.innerWidth * .07, 95);
                    } else {
                        canvasPaddingLeft = window.innerWidth * .1;
                        canvasPaddingRight = window.innerWidth * .1;
                    }
                }

                const currentSlideIndex = slides.indexOf(currentSlide);

                const calcPrimaryCanvasScale = availableBounds => {
                    let spacing = 125;
                    if (activePanel && activePanelProps && activePanelProps.position === PositionType.BOTTOM) {
                        spacing = 0;
                    }
                    let scale = Math.min(availableBounds.height / (canvasHeight + spacing), availableBounds.width / canvasWidth).toFixed(3);
                    scale = Math.min(scale, 1.25);
                    return scale;
                };

                const $canvasContainer = $(this.canvasContainerRef.current);
                const $editorContainerRef = $(this.editorContainerRef.current);
                const containerBounds = new geom.Rect(0, 0, $canvasContainer.width(), $canvasContainer.height() - 20);

                this.removeFileDropOverlay($editorContainerRef);

                // render the file drop overlay if the active panel is not the add element panel
                if (activePanel !== PanelType.ADD_ELEMENT) {
                    this.renderFileDropOverlay($editorContainerRef);
                }

                let availableBounds = containerBounds.deflate({ left: canvasPaddingLeft, right: canvasPaddingRight });

                let primaryCanvasScale = calcPrimaryCanvasScale(availableBounds);
                let primaryCanvasSize = new geom.Size(canvasWidth, canvasHeight).scale(primaryCanvasScale);
                let primaryCanvasBounds = new geom.Rect(availableBounds.centerH - primaryCanvasSize.width / 2, containerBounds.top, primaryCanvasSize);

                // recalculate with the actual available container bounds depending on the visibility and position of any active panel
                if (activePanel && activePanelProps) {
                    switch (activePanelProps.position) {
                        case PositionType.LEFT:
                            if (activePanelProps.size > primaryCanvasBounds.left) {
                                availableBounds = containerBounds.deflate({ left: activePanelProps.size, right: 20 });
                            }
                            break;
                        case PositionType.RIGHT:
                            if (COMMENT_PANEL_SIZE > availableBounds.width - primaryCanvasBounds.right) {
                                availableBounds = containerBounds.deflate({ right: activePanelProps.size + 80, left: canvasPaddingRight });
                            }
                            break;
                        case PositionType.BOTTOM:
                            let controlsHeight = activePanelProps.showControls ? CONTROLS_HEIGHT : BOTTOM_CANVAS_MARGIN;
                            if (activePanelProps.size > availableBounds.height - primaryCanvasBounds.bottom - controlsHeight) {
                                availableBounds = containerBounds.deflate({ bottom: activePanelProps.size + controlsHeight });
                            }
                            break;
                    }
                }

                // recalculate primary canvas props in case availableBounds was changed by panels
                primaryCanvasScale = calcPrimaryCanvasScale(availableBounds);
                primaryCanvasSize = new geom.Size(canvasWidth, canvasHeight).scale(primaryCanvasScale);
                primaryCanvasBounds = new geom.Rect(availableBounds.centerH - primaryCanvasSize.width / 2, availableBounds.top, primaryCanvasSize);

                // calculate secondary canvas props
                const secondaryCanvasScale = primaryCanvasScale * SECONDARY_CANVAS_SCALE;
                const secondaryCanvasWidth = canvasWidth * secondaryCanvasScale;

                // set starting x position scrolled to current slide
                let x = primaryCanvasBounds.left - currentSlideIndex * (secondaryCanvasWidth + SLIDE_GAP);

                // reset wrappers
                this.canvasWrappers = {};

                // go through the slides and build layout props
                const slideLayoutProps = [];
                for (const slide of slides) {
                    const slideLayout = {
                        id: slide.id,
                        model: slide
                    };

                    if (slide === currentSlide) {
                        slideLayout.scale = primaryCanvasScale;
                        slideLayout.opacity = 1;
                        slideLayout.blur = 0;
                        slideLayout.isCurrent = true;
                    } else {
                        slideLayout.scale = primaryCanvasScale * SECONDARY_CANVAS_SCALE;
                        slideLayout.opacity = SECONDARY_SLIDE_OPACITY;
                        slideLayout.blur = 10;
                    }

                    slideLayout.x = x;
                    slideLayout.y = (availableBounds.top + (primaryCanvasBounds.height - (canvasHeight * slideLayout.scale)) / 2).toFixed(3);
                    slideLayout.width = canvasWidth * slideLayout.scale;
                    slideLayout.height = canvasHeight * slideLayout.scale;

                    x += canvasWidth * slideLayout.scale + SLIDE_GAP;

                    slideLayoutProps.push(slideLayout);

                    const controller = canvasControllers[slideLayout.id];

                    // We need to set the canvasScale for each canvas
                    controller.canvasScale = slideLayout.scale;
                    controller.maxCanvasScale = Math.max(primaryCanvasScale, slideLayout.scale);

                    this.canvasWrappers[slideLayout.id] = controller.withState(CanvasWrapper);
                }

                if (prevSlideLayoutProps && transition) {
                    const prevCurrentPropsIndex = prevSlideLayoutProps.findIndex(props => props.isCurrent);
                    const prevCurrentProps = prevSlideLayoutProps[prevCurrentPropsIndex];

                    const currentPropsIndex = slideLayoutProps.findIndex(props => props.isCurrent);
                    const currentProps = slideLayoutProps[currentPropsIndex];

                    if (prevCurrentPropsIndex === currentPropsIndex) {
                        if (currentProps.x === prevCurrentProps.x &&
                            currentProps.y === prevCurrentProps.y &&
                            currentProps.scale === prevCurrentProps.scale) {
                            transition = false;
                        }
                    }
                }

                const stateUpdates = {
                    slideLayoutProps,
                    primaryCanvasBounds,
                    primaryCanvasScale,
                    transition,
                    availableBounds,
                };

                if (!activePanel) {
                    // only set the available space below primary canvas if there is no active panel
                    stateUpdates.availableSpaceBelowPrimaryCanvas = availableBounds.height - primaryCanvasBounds.bottom;
                }

                await this.setStateAsync(stateUpdates);
            }

            handleCanvasClick = slideModel => {
                const { currentSlide, currentCanvasController, isSingleSlideEditor } = this.props;

                if (slideModel !== currentSlide) {
                    // navigate to the clicked slide
                    PresentationEditorController.setCurrentSlide(slideModel);
                } else if (slideModel.isLibrarySlide() && !isSingleSlideEditor) {
                    currentCanvasController.editLibrarySlide();
                }
            }

            handleClickNothing = event => {
                if (event.target.id !== "canvas-container") return;
                ds.selection.element = null;
                ds.selection.rolloverElement = null;
            }

            handleCurrentCanvasMouseDown = (event, slideLayoutModel, isCurrentCanvas) => {
                const { currentCanvasController } = this.props;
                if (currentCanvasController.isTemplateObsolete && isCurrentCanvas) {
                    event.stopPropagation();
                    event.preventDefault();

                    PresentationEditorController.updateCurrentSlideTemplate();
                    return;
                }

                this.handleCanvasClick(slideLayoutModel);
            }

            renderSelectionLayer = () => {
                return new Promise((resolve, reject) => {
                    this.renderSelectionLayerPromiseChain = this.renderSelectionLayerPromiseChain
                        .then(async () => {
                            const { currentCanvasController } = this.props;

                            await this.clearSelectionLayer(false);

                            // Create a selection layer
                            const canvas = currentCanvasController?.canvas;
                            if (canvas) {
                                const { SelectionLayer } = await getCanvasBundle(canvas.bundleVersion);

                                await currentCanvasController.setEditable(true);

                                this.selectionLayer = new SelectionLayer({ canvas });
                                $(this.selectionLayerRef.current).append(this.selectionLayer.$el);

                                await PresentationEditorController.setCurrentSelectionLayer(this.selectionLayer);
                            }
                        })
                        .then(resolve)
                        .catch(reject);
                });
            }

            clearSelectionLayer = async (waitForRenderSelectionLayerPromise = true) => {
                if (waitForRenderSelectionLayerPromise) {
                    await this.renderSelectionLayerPromiseChain;
                }

                const { currentCanvasController } = this.props;

                ds.selection.element = null;

                if (this.selectionLayer) {
                    this.selectionLayer.remove();
                    this.selectionLayer = null;
                    await PresentationEditorController.setCurrentSelectionLayer(null);
                }

                $(this.selectionLayerRef.current).empty();

                await currentCanvasController?.setEditable(false);
            }

            handleCanvasTransitionEnded = () => {
                const { currentSlide, presentation } = this.props;

                this.isScrolling = false;
                this.setState({ transition: false });
                PresentationEditorController.showCanvasControls();

                if (this.currentSlideChangeRequest?.slideId === currentSlide.id) {
                    logger.metric(MetricName.ADVANCE_TO_SLIDE_TIME, {
                        presentationId: presentation.id,
                        slideId: currentSlide.id,
                        advanceTimeMs: Date.now() - this.currentSlideChangeRequest.timestamp
                    });
                }

                this.currentSlideChangeRequest = null;
            }

            handlePanelCallbacks = (state, panelProps) => {
                switch (state) {
                    case "entering":
                        if (!panelProps.keepSelection) {
                            ds.selection.element = null;
                        }
                        this.setState({
                            activePanelProps: panelProps,
                            isTransitioningPanel: true,
                        }, () => {
                            this.calculateSlideLayout();
                        });
                        break;
                    case "entered":
                        this.setState({ isTransitioningPanel: false });
                        if (panelProps.showControls) {
                            if (this.selectionLayer) {
                                this.selectionLayer.refreshSelectionLayer();
                            }
                        }
                        break;
                    case "exiting":
                        this.setState({ isTransitioningPanel: true });
                        break;
                    case "exited":
                        this.setState({
                            isTransitioningPanel: false,
                            showControls: true
                        });
                        if (this.selectionLayer) {
                            this.selectionLayer.refreshSelectionLayer();
                        }
                        break;
                }
            }

            handlePanelStartResize = () => {
                this.setState({ isTransitioningPanel: true });
            }

            handlePanelEndResize = () => {
                this.setState({ isTransitioningPanel: false });
                if (this.selectionLayer) {
                    this.selectionLayer.refreshSelectionLayer();
                }
            }

            handlePanelResize = panelProps => {
                this.setState({
                    activePanelProps: panelProps
                }, () => {
                    this.calculateSlideLayout(false);
                });
            }

            handleElementPanelCallbacks = (state, panelProps) => {
                if (state === "entering") {
                    $(this.elementPanelContainerRef.current).append(this.props.elementPanelView.render().$el);
                    this.props.elementPanelView.onShown();
                    this.props.elementPanelView.once("close", () => {
                        PresentationEditorController.closeAllPanels();
                    });
                }
                this.handlePanelCallbacks(state, panelProps);
            }

            handleClosePanel = () => {
                PresentationEditorController.closeAllPanels();
            }

            handleCloseSlideGrid = async focusedSlide => {
                await PresentationEditorController.toggleSlideGrid();
                await PresentationEditorController.setCurrentSlide(focusedSlide);
            }

            handleCanvasReloaded = async slide => {
                const { currentSlide, showSelectionLayer } = this.props;
                if (slide === currentSlide) {
                    await this.clearSelectionLayer();
                    await this.calculateSlideLayout(false);
                    if (showSelectionLayer) {
                        await this.renderSelectionLayer();
                    }
                }
            }

            handleHideBanner = () => {
                this.setState({ showUpdateBanner: false });
                sessionStorage.setItem("hideUpdateBanner", "true");
            }

            renderCanvasContainer() {
                const {
                    currentSlide,
                    showCanvasControls,
                    showSelectionLayer,
                    activePanel,
                    currentCanvasController,
                    isSingleSlideEditor,
                } = this.props;
                const {
                    slideLayoutProps,
                    primaryCanvasBounds,
                    transition,
                    isTransitioningPanel = false,
                    isDragging,
                    activePanelProps = {},
                } = this.state;

                const isSelectionLayerVisible = (
                    showSelectionLayer &&
                    !transition &&
                    !isTransitioningPanel &&
                    (
                        !activePanel ||
                        activePanelProps.showControls
                    )
                );

                return (<>
                    <CanvasContainer
                        id="canvas-container"
                        ref={this.canvasContainerRef}
                        onMouseDown={this.handleClickNothing}
                    >
                        {primaryCanvasBounds && <>
                            {slideLayoutProps.map(slideLayout => {
                                const CanvasWrapperWithState = this.canvasWrappers[slideLayout.id];
                                const isCurrentCanvas = slideLayout.model === currentSlide;
                                return (
                                    <PositionedCanvas
                                        key={slideLayout.id}
                                        className="positioned-canvas"
                                        layout={slideLayout}
                                        showTransition={transition}
                                        onTransitionEnd={isCurrentCanvas ? this.handleCanvasTransitionEnded : () => { }}
                                        onMouseDown={event => this.handleCurrentCanvasMouseDown(event, slideLayout.model, isCurrentCanvas)}
                                    >
                                        <CanvasWrapperWithState onCanvasReloaded={() => this.handleCanvasReloaded(slideLayout.model)} />
                                    </PositionedCanvas>
                                );
                            })}

                            <SelectionLayerFrame
                                ref={this.selectionLayerRef}
                                canvasBounds={primaryCanvasBounds}
                                visible={isSelectionLayerVisible}
                            />

                            {activePanel === PanelType.ADD_ELEMENT &&
                                <AddElementDropZone currentCanvas={currentCanvasController.canvas}
                                    canvasBounds={primaryCanvasBounds} isDragging={isDragging}
                                    onClose={this.handleClosePanel}
                                />
                            }
                        </>}
                    </CanvasContainer>

                    {primaryCanvasBounds && !isSingleSlideEditor &&
                        <RightCanvasControls
                            canvasBounds={primaryCanvasBounds}
                            visible={showCanvasControls}
                            transition={transition}
                        >
                            <SlideActions />
                        </RightCanvasControls>
                    }
                    <ShortcutPanel />
                </>);
            }

            render() {
                const {
                    currentSlide,
                    currentCanvasController,
                    activePanel,
                    showSlideGrid,
                    isSingleSlideEditor,
                    hidePanels,
                    allowSharedSlideEditing = false
                } = this.props;
                const {
                    availableSpaceBelowPrimaryCanvas,
                    activePanelProps,
                    availableBounds,
                    isReady,
                    showUpdateBanner
                } = this.state;

                if (!isReady) {
                    return <Spinner />;
                }

                const LeftSidePanels = [
                    {
                        type: PanelType.COLOR,
                        width: 125,
                        backgroundColor: "#222",
                        children: <ColorPanel />
                    },
                    {
                        type: PanelType.LAYOUT,
                        width: 270,
                        children: <LayoutPanel />
                    },
                    {
                        type: PanelType.VARIATIONS,
                        width: 200,
                        children: <VariationsPanel />
                    },
                    {
                        type: PanelType.ADD_ELEMENT,
                        width: 110,
                        children: <AddElementPanel setIsDragging={isDragging => this.setState({ isDragging })} />
                    },
                    {
                        type: PanelType.ANIMATION,
                        width: 230,
                        showControls: true,
                        children: <AnimationPanel />
                    },
                    {
                        type: PanelType.RECORD,
                        width: 230,
                        showControls: true,
                        children: <RecordPanel />
                    },
                    {
                        type: PanelType.VERSION_HISTORY,
                        width: 225,
                        children: <RevisionPanel
                            key={currentSlide?.id} // Force reload component when slide changes
                            currentSlide={currentSlide}
                            currentCanvasController={currentCanvasController}
                        />
                    },
                ]
                    .filter(({ type }) => !hidePanels.includes(type))
                    .map(({ type, children, ...rest }) => (
                        <LeftSidePanel
                            key={type}
                            visible={activePanel === type}
                            callbacks={this.handlePanelCallbacks}
                            onClose={this.handleClosePanel}
                            {...rest}
                        >
                            {children}
                        </LeftSidePanel>
                    ));

                const RightSidePanels = [
                    {
                        type: PanelType.COMMENTS,
                        width: COMMENT_PANEL_SIZE,
                        showControls: true,
                        children: <EditorCommentsPane goToSlide={this.goToSlide} />
                    }
                ]
                    .filter(({ type }) => !hidePanels.includes(type))
                    .map(({ type, width, showControls, children }) => (
                        <RightSidePanel
                            key={type}
                            showControls={showControls}
                            visible={activePanel === type}
                            callbacks={this.handlePanelCallbacks}
                            onClose={showControls ? this.handleClosePanel : null}
                            width={width}
                        >
                            {children}
                        </RightSidePanel>
                    ));

                const defaultTeams = ds.teams.getDefaultTeams();
                const hasTeams = defaultTeams.length > 0;
                const isOwnerInATeam = defaultTeams.some(team => team.getUserRole() === "owner");

                const showBanner = showUpdateBanner && !app.isConstrained;

                return (
                    <ThemeProvider theme={dialogTheme}>
                        <DndProvider backend={HTML5Backend}>
                            <Container id="presentation-editor-container">
                                {showBanner && !app.isConstrained && !app.user.get("_migrated") && (
                                    <UpdateBanner
                                        hasTeams={hasTeams}
                                        isOwnerInATeam={isOwnerInATeam}
                                        onDismiss={this.handleHideBanner}
                                        onGetPersonalUpdate={() => {
                                            AppController.handleGetPersonalUpdate();
                                        }}
                                        onGetTeamUpdate={() => {
                                            AppController.handleGetTeamUpdate();
                                        }}
                                    />
                                )}
                                {isSingleSlideEditor && <SharedSlideEditorMenuBar />}
                                {!isSingleSlideEditor && <PresentationMenuBar />}
                                <InnerContainer>
                                    <PresentationSideMenuBar
                                        allowSharedSlideEditing={allowSharedSlideEditing}
                                        hidePanels={hidePanels}
                                    />

                                    <EditorContainer id="editor-container" ref={this.editorContainerRef}>
                                        {LeftSidePanels}

                                        {RightSidePanels}

                                        <BottomPanel
                                            visible={activePanel === PanelType.NOTES}
                                            activePanelProps={activePanelProps}
                                            height={Math.max(availableSpaceBelowPrimaryCanvas - CONTROLS_HEIGHT, 300)}
                                            showControls
                                            resizable
                                            availableBounds={availableBounds}
                                            callbacks={this.handlePanelCallbacks}
                                            onClose={this.handleClosePanel}
                                            onStartResize={this.handlePanelStartResize}
                                            onResize={this.handlePanelResize}
                                            onEndResize={this.handlePanelEndResize}
                                        >
                                            <SpeakerNotes onClose={() => this.handleClosePanel()} />
                                        </BottomPanel>

                                        <BottomPanel
                                            visible={activePanel === PanelType.ELEMENT}
                                            activePanelProps={activePanelProps}
                                            height={Math.max(availableSpaceBelowPrimaryCanvas - BOTTOM_CANVAS_MARGIN, 300)}
                                            resizable
                                            availableBounds={availableBounds}
                                            callbacks={this.handleElementPanelCallbacks}
                                            onClose={this.handleClosePanel}
                                            onStartResize={this.handlePanelStartResize}
                                            onResize={this.handlePanelResize}
                                            onEndResize={this.handlePanelEndResize}
                                        >
                                            <ElementPanelContainer ref={this.elementPanelContainerRef} />
                                        </BottomPanel>

                                        {this.renderCanvasContainer()}

                                        {!isSingleSlideEditor &&
                                            <Transition in={showSlideGrid} timeout={500} mountOnEnter unmountOnExit>
                                                {state => (
                                                    <SlideGridContainer state={state}>
                                                        <SlideGridWrapper onClose={this.handleCloseSlideGrid} />
                                                    </SlideGridContainer>
                                                )}
                                            </Transition>
                                        }
                                    </EditorContainer>

                                    {!isSingleSlideEditor &&
                                        <CollaborationBar
                                            showComments
                                            showSlideStatus
                                            showAssignUser
                                            showCollaboration
                                        />
                                    }
                                </InnerContainer>

                            </Container>
                        </DndProvider>
                    </ThemeProvider>
                );
            }
        }
    )
);

const PresentationEditorInitializer =
    PresentationEditorController.withState(
        function PresentationEditorInitializer({
            presentationId,
            initialSlideId,
            initialSlideIndex,
            isSingleSlideEditor,
            onLoaded,
            hidePanels,
            allowThemeChange,
            allowSharedSlideEditing = false,
            renderSettings,
            pane,
            dummyPresentation = null,
            // Comes from PresentationEditorController
            presentation,
            isInitialized,
        }) {
            useEffect(() => {
                const name = presentation?.get("name");
                if (name) {
                    document.title = "Beautiful.ai - " + name;
                } else {
                    document.title = "Beautiful.ai";
                }

                return () => {
                    document.title = "Beautiful.ai";
                };
            }, [presentation?.get("name")]);

            useEffect(() => {
                if (!isInitialized) {
                    return;
                }

                if (typeof initialSlideIndex === "number" && initialSlideIndex !== PresentationEditorController.getCurrentSlideIndex()) {
                    if (PresentationEditorController.isSlideGridVisible()) {
                        PresentationEditorController.toggleSlideGrid();
                    }

                    PresentationEditorController.setCurrentSlideByIndex(initialSlideIndex, true, true);
                }
            }, [initialSlideIndex]);

            useEffect(() => {
                const loadStartTime = Date.now();

                (async () => {
                    // If we are on mobile, we need to load the presentation in the
                    // presentation player mode and not editor
                    if (app.isMobileOrTablet) {
                        AppController.playPresentation({ presentationId, slideIndex: initialSlideIndex });
                        return;
                    }

                    if (!presentationId) {
                        await PresentationEditorController.reset();
                        return;
                    }

                    const presentation = dummyPresentation ?? await getPresentation(presentationId, { permission: "write", autoSync: true });

                    // If collaborator with read-only access, redirect to player
                    if (presentation.permissions.read && !presentation.permissions.write) {
                        if (presentation.get("isTemplate")) {
                            AppController.showLibrary();
                        } else {
                            AppController.playPresentation({ presentationId, slideIndex: initialSlideIndex }, { goBackToLibrary: true });
                        }
                        return;
                    }

                    // check if the slide ID provided still exists in the presentation
                    if (initialSlideId) {
                        const slide = presentation.getSips().find(slideId => slideId === initialSlideId);
                        if (!slide) {
                            await ShowWarningDialog({
                                title: "Slide not found",
                                message: "The slide you are trying to access does not exist in the presentation.",
                                acceptButtonText: "Go to first slide",
                                acceptCallback: () => {
                                    // load the initial slide index if the slide ID is not found
                                    initialSlideId = null; initialSlideIndex = initialSlideIndex ?? 0;
                                }
                            });
                        } else {
                            initialSlideIndex = presentation.getSips().indexOf(initialSlideId);
                        }
                    }

                    await PresentationEditorController.loadPresentation(presentation, initialSlideIndex, isSingleSlideEditor, allowThemeChange, hidePanels);

                    if (renderSettings) {
                        ShowDialog(PresentationSettingsContainer, { startPane: pane, presentation });
                    }

                    logger.metric(MetricName.EDITOR_LOAD_TIME, {
                        presentationId,
                        loadTimeMs: Date.now() - loadStartTime,
                        loadFailed: false
                    });
                })().catch(err => {
                    logger.error(err, "[PresentationEditorInitializer] failed to load presentation", err);

                    if (err instanceof PresentationPermissionDeniedError) {
                        ShowDialog(RequestAccessDialog, {
                            presentationOrLinkId: presentationId,
                            contextType: RequestAccessDialogContextType.EDITOR
                        });
                    } else if (err instanceof PresentationNotFoundError) {
                        ShowErrorDialog({
                            title: "Presentation not found",
                            message: "Presentation not found, make sure your link is correct.",
                            acceptCallback: () => AppController.showLibrary()
                        });
                    } else if (err instanceof FailedToRenderCanvasError) {
                        ShowErrorDialog({
                            title: "Failed to render presentation",
                            message: <>Sorry, we couldn't load some slides in your presentation, please contact us at <a href="mailto:support@beautiful.ai">support@beautiful.ai</a></>,
                            acceptCallback: () => AppController.showLibrary()
                        });
                    } else {
                        ShowErrorDialog({
                            title: "Failed to load presentation",
                            message: "Sorry, something went wrong, please try again later.",
                            acceptCallback: () => AppController.showLibrary()
                        });
                    }

                    logger.metric(MetricName.EDITOR_LOAD_TIME, {
                        presentationId,
                        loadTimeMs: Date.now() - loadStartTime,
                        loadFailed: true
                    });
                });

                return () => {
                    (async () => {
                        if (window.appLoaderController.stateForced) {
                            // Ugly hack to prevent legacy library from showing up for a second before the
                            // actual switch to non-legacy app happens
                            RestoreSplashScreen();
                        }

                        // First, mark the presentation as dirty so that the thumbnails show spinners
                        if (PresentationLibraryController.isInitialized) {
                            PresentationLibraryController.markPresentationsDirty([presentationId]);
                        }

                        // Controller reset will trigger finishedEditing for current slide that may update the presentation
                        // timestamps
                        await PresentationEditorController.reset();

                        // Force refresh the presentation in the library so that the timestamps and thumbnails are updated
                        if (PresentationLibraryController.isInitialized) {
                            PresentationLibraryController.forceRefreshPresentations([presentationId]);
                        }

                        if (window.appLoaderController.stateForced) {
                            RemoveSplashScreen();
                        }

                        // We have to wait for the editor to fully reset and do its thing before
                        // resetting app state because the reset will disconnect presentation adapter which
                        // won't allow editor to make final changes
                        window.appLoaderController.resetAppState();
                    })();
                };
            }, [presentationId]);

            if (!isInitialized) {
                return <Spinner />;
            }

            return <PresentationEditor allowSharedSlideEditing={allowSharedSlideEditing} onLoaded={onLoaded} />;
        }
    );

export default PresentationEditorInitializer;
