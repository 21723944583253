import React, { Component } from "reactn";
import { Icon } from "@material-ui/core";
import styled from "styled-components";

import Api from "js/core/api";
import { trackActivity, trackState } from "js/core/utilities/utilities";
import { getStaticUrl } from "js/config";
import { app } from "js/namespaces";
import { ds } from "js/core/models/dataService";
import { CustomerType } from "legacy-common/constants";
import { _ } from "legacy-js/vendor";
import { FeatureType } from "js/core/models/features";

import Loadable from "legacy-js/react/components/Loadable";
import { Gap10, Gap20 } from "legacy-js/react/components/Gap";
import TeamMembersList from "legacy-js/react/views/UserOptions/components/TeamMembersList";
import {
    Section,
    UIPane,
    UIPaneContents,
    UIPaneHeader,
    BlueButton
} from "legacy-js/react/components/UiComponents";
import { FlexBox } from "legacy-js/react/components/LayoutGrid";
import { ShowDialog } from "legacy-js/react/components/Dialogs/BaseDialog";
import AppController from "legacy-js/core/AppController";

import { TextInput } from "../components";
import { InviteTeamMembersDialog } from "../dialogs";
import AddExistingMemberDialog from "../dialogs/AddExistingMemberDialog";
import ManageAvailableTeamSeatsDialog from "../dialogs/ManageAvailableTeamSeatsDialog";
import { getUsedSeatCount } from "../UserOptionsContainer";
import BillingDataService from "../dataservices/billingDataService";

const SeatCount = styled.div`
  font-size: 14px;
  margin-top: 20px;       
  font-weight: 700;  
`;

const StyledFlexBox = styled(FlexBox)`
  font-weight: bold;
`;

const StyledLink = styled.a`
  color: #11A9E2;
  margin-left: 2px;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: #0D76A2;
  }
`;

const StyledUpdateContent = styled.div`
    color: #666;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.5px;
`;

const StyledList = styled.div`
    display: flex;
    background: #F7FAFC;
    width: 400px;
    padding: 20px 30px;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    flex-shrink: 0;
    align-self: stretch;
    margin-top: -20px;
`;

const StyledListItem = styled.div`
    display: flex;
    justify-content: top;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;

    > p {
        color: #222;
        font-feature-settings: 'liga' off, 'clig' off;
        font-family: "Source Sans Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        margin: 0;
        padding: 0;
    }

    > img {
        margin-top: 7px;
        width: 8.333px;
        height: 6.667px;
        flex-shrink: 0;
    }
`;

const LinkButton = styled.span`
    color: #11a9e2;
    cursor: pointer;
`;

class ManageTeamPane extends Component {
    constructor(props) {
        super(props);

        this.state = {
            org: {},
            isLoading: true,
            team: false,
            usedSeatCount: 0,
            userRole: "",
            transactionHistoryData: [],
            subscription: { items: { data: [{ quantity: 0 }] } },
            paymentMethod: null,
            canManageTeam: app.user.features.isFeatureEnabled(FeatureType.MANAGE_TEAM, props.organizationId),
        };

        this.contentsContainerRef = React.createRef();
    }

    get orgTotalSeatCount() {
        const { subscription } = this.state;
        return subscription?.items?.data[0].quantity ?? 0;
    }

    get isActiveSubscription() {
        const { subscription } = this.state;
        return subscription && !subscription.cancel_at_period_end;
    }

    get isValidSubscription() {
        const { subscription } = this.state;
        return (
            !!subscription &&
            (
                subscription?.status === "active" ||
                subscription?.status === "trialing"
            )
        );
    }

    async componentDidMount() {
        await this.getFreshState();
    }

    async componentDidUpdate(prevProps) {
        // org id can change if user changes workspace.
        // isDefaultTeam can change if owner switches from viewing a specific team to all team members view.
        if (this.props.organizationId !== prevProps.organizationId || prevProps.isDefaultTeam !== this.props.isDefaultTeam) {
            this.setState({ org: {}, isLoading: true });
            await this.getFreshState();
            this.setState({ isLoading: false });
        }
    }

    fetchSubscription = async () => {
        const { organizationId } = this.props;
        const [paymentMethodsResponse, subscription, customer, isEnterprise] = await Promise.all([
            Api.paymentMethods.get({
                customer_type: CustomerType.TEAM,
                organization_id: organizationId
            }),
            BillingDataService.fetchSubscription(organizationId),
            BillingDataService.fetchCustomer(organizationId),
            Api.orgIsEnterprise.get({ orgId: organizationId })
        ]);

        this.setState({
            subscription,
            paymentMethod: paymentMethodsResponse.body[0],
            isEnterprise,
            team: {
                ...this.state.team,
                name: subscription?.customerName ?? customer.name
            }
        });
    }

    getFreshState = async () => {
        const { isDefaultTeam } = this.props;
        if (isDefaultTeam) {
            const orgResponse = await Api.organizations.get({ id: this.props.organizationId });
            this.setState({ org: orgResponse });
        }
        await this.getTeam();
        if (this.state.canManageTeam) {
            await this.fetchSubscription();
        }
        this.setState({
            isLoading: false
        });
    }

    getTeam = async () => {
        const { teamId } = this.props;
        const teamResponse = await Api.teams.get({ id: teamId });
        const teamInvitesResponse = await Api.teamInvites.get({
            type: "team",
            id: teamId
        });
        const allMembers = teamResponse.body.members.concat(teamInvitesResponse.body);
        const user = allMembers.find(member => member.id === app.user.id);
        this.setUsedSeatCount(allMembers);

        this.setState({
            originalName: teamResponse.body.name,
            team: {
                ...teamResponse.body,
                members: allMembers
            },
            userRole: user ? user.role : "",
        });
    }

    handleTeamNameChange = event => {
        const name = event.target.value;
        this.setState(prevState => ({
            team: {
                ...prevState.team,
                name
            }
        }));
    }

    handleTeamNameBlurred = async (teamId, teamName) => {
        const { handleTeamNameChange, isDefaultTeam, organizationId } = this.props;
        const { originalName } = this.state;
        const trimmedTeamName = teamName.trim();
        const trimmedOriginalName = originalName.trim();

        // Add this check to prevent saving an empty team name
        if (trimmedTeamName === "") {
            // Revert to the original name if the new name is empty
            this.setState(prevState => ({
                team: {
                    ...prevState.team,
                    name: trimmedOriginalName
                }
            }));
            return;
        }
        if (trimmedTeamName === trimmedOriginalName) {
            return;
        }
        const updatedTeam = ds.teams.find(team => team.id == teamId);
        //if an owner is part of a team the team will be part of ds.teams otherwise an API call will be made to change
        //the team name. Need to check for existence of updatedTeam in case the owner is not part of the team
        if (updatedTeam && updatedTeam.get("isDefaultTeam")) {
            Api.organizations.put({ id: organizationId }, { type: "change_name", teamId, teamName: trimmedTeamName });
            const theme = ds.sharedThemes.findWhere({ id: updatedTeam.get("sharedThemeId") });
            theme.update({ name: trimmedTeamName });
        } else if (updatedTeam) {
            updatedTeam.update({ name: trimmedTeamName });
        } else {
            await Api.teams.put({ type: "change_team_name", teamId, teamName: trimmedTeamName });
        }
        if (isDefaultTeam) {
            const props = {
                workspace_id: organizationId,
                old_organization_name: trimmedOriginalName,
                new_organization_name: trimmedTeamName
            };
            trackState({
                "organization_name": trimmedTeamName
            });
            trackActivity("Organization", "NameChanged", null, null, props, { audit: true });
        } else {
            const props = {
                workspace_id: organizationId,
                team_id: teamId,
                old_team_name: trimmedOriginalName,
                new_team_name: trimmedTeamName
            };
            trackActivity("OrgTeam", "Renamed", null, null, props, { audit: true });
        }
        this.setState(prevState => {
            return {
                ...prevState,
                team: {
                    ...prevState.team,
                    name: trimmedTeamName
                }
            };
        });

        if (isDefaultTeam && handleTeamNameChange) {
            handleTeamNameChange(teamName);

            await Api.subscriptions.put({
                teamName,
                customerType: CustomerType.TEAM,
                orgId: organizationId,
                type: "update_customer_name",
            });
        }
    }

    setUsedSeatCount = teamMembers => {
        const usedSeatCount = getUsedSeatCount(teamMembers);
        this.setState({ usedSeatCount });
    }

    handleShowAddMemberDialog = () => {
        const { team, usedSeatCount, org, isEnterprise } = this.state;
        const { isDefaultTeam, organizationId } = this.props;
        const AddMemberDialog = isDefaultTeam ? InviteTeamMembersDialog : AddExistingMemberDialog;
        const props = {};
        if (isDefaultTeam) {
            props.usedSeatCount = usedSeatCount;
            props.org = org;
        }
        ShowDialog(AddMemberDialog, {
            organizationId,
            team,
            callback: () => this.getFreshState(),
            isEnterprise,
            ...props
        });
    }

    handleRemoveAvailableSeats = async () => {
        const { usedSeatCount } = this.state;
        const { organizationId } = this.props;

        await BillingDataService.removeTeamSeats(organizationId, usedSeatCount);
        await this.getFreshState();
    }

    handleShowManageAvailableSeatsDialog = () => {
        const { usedSeatCount } = this.state;

        ShowDialog(ManageAvailableTeamSeatsDialog, {
            unusedSeatCount: this.orgTotalSeatCount - usedSeatCount,
            showAddMemberDialog: this.handleShowAddMemberDialog,
            removeAvailableSeats: this.handleRemoveAvailableSeats
        });
    }

    renderSeatCount = () => {
        const { usedSeatCount, canManageTeam, paymentMethod, isEnterprise } = this.state;

        // if this.isActiveSubscription is undefined, it means we do not have an active subscription
        // and we should not show the seat count
        if (usedSeatCount == this.orgTotalSeatCount || this.isActiveSubscription === undefined) {
            const canManage = canManageTeam && this.isActiveSubscription;

            let label = "Add Team Pro Seats";
            const linkProps = {};
            if (canManage) {
                if (isEnterprise || !paymentMethod) {
                    label = "Contact us to add more seats.";
                    const recipient = isEnterprise ? "accounts@beautiful.ai" : "support@beautiful.ai";
                    linkProps.href = `mailto:${recipient}`;
                } else if (paymentMethod) {
                    linkProps.onClick = this.handleShowAddMemberDialog;
                }
            }

            return (
                <StyledFlexBox left>
                    You have no available Team Pro seats.&nbsp;
                    {canManage && <StyledLink {...linkProps}>{label}</StyledLink>}
                </StyledFlexBox>
            );
        }

        const remainingSeats = this.orgTotalSeatCount - usedSeatCount;
        const hasAvailableSeats = remainingSeats > 0;

        return (<StyledFlexBox left>
            {remainingSeats} of {this.orgTotalSeatCount} Team Pro {"seat".pluralize(this.orgTotalSeatCount > 1)} available.
            {canManageTeam && this.isValidSubscription &&
                // if enterprise user DO NOT show the manage seats button, otherwise check if it has payment method or available seats
                (!isEnterprise && (paymentMethod || hasAvailableSeats)) &&
                <StyledLink onClick={this.handleShowManageAvailableSeatsDialog}>Manage seats</StyledLink>}
        </StyledFlexBox>);
    }

    render() {
        const { handleRemoveMember, isDefaultTeam, paneClicked } = this.props;
        const { isLoading, team, canManageTeam, org, usedSeatCount, paymentMethod, isEnterprise } = this.state;

        const hasAvailableSeats = (this.orgTotalSeatCount - usedSeatCount) > 0;
        const canAddMoreMembers = isEnterprise || paymentMethod || hasAvailableSeats;

        return (
            <UIPane className="manage-team">
                <UIPaneHeader>
                    Manage Team
                </UIPaneHeader>
                <UIPaneContents containerRef={this.contentsContainerRef}>
                    <Loadable isLoading={isLoading}>
                        <Section>
                            <Gap10 />
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <TextInput
                                        label={isDefaultTeam ? "ORGANIZATION NAME" : "TEAM NAME"}
                                        id="team-name"
                                        curValue={team.name}
                                        handleChange={event => this.handleTeamNameChange(event)}
                                        handleBlur={() => this.handleTeamNameBlurred(team.id, team.name)}
                                        disabled={!canManageTeam}
                                        maxLength={50}
                                    />
                                    {isDefaultTeam && canManageTeam && <SeatCount>{this.renderSeatCount()}</SeatCount>}
                                </div>
                                {isDefaultTeam && canManageTeam && canAddMoreMembers && this.isValidSubscription &&
                                    <BlueButton
                                        id="add-member-button"
                                        style={{ height: 44 }}
                                        onClick={this.handleShowAddMemberDialog}
                                    >
                                        <Icon>add</Icon>
                                        Add a member
                                    </BlueButton>
                                }
                                {!isDefaultTeam && canManageTeam &&
                                    <BlueButton
                                        id="add-member-button"
                                        style={{ height: 44 }}
                                        onClick={this.handleShowAddMemberDialog}
                                    >
                                        <Icon>add</Icon>
                                        Add members to this team
                                    </BlueButton>
                                }
                            </div>
                        </Section>
                        {canManageTeam && <>
                            <Gap20 />
                            <Section className="upgrade-section" title="Give your team the new version of Beautiful.ai!">
                                <FlexBox left horizontal gap={10}>
                                    <div>
                                        <StyledUpdateContent >
                                            We’ve made the presentation editing experience more powerful, flexible and easier to navigate with a refreshed UI. As team Owner, updating your team workspace will update everyone in your organization to the new editor UI. You have until March 3 to update, at which point your team will be updated automatically. <LinkButton onClick={() => {
                                                window.open("https://support.beautiful.ai/hc/en-us/articles/31146296163597", "_blank");
                                            }}> Learn more about the update</LinkButton>
                                        </StyledUpdateContent>
                                        <BlueButton style={{ marginTop: "20px" }} onClick={() => AppController.handleGetTeamUpdate()}>Update your team</BlueButton>
                                    </div>
                                    <StyledList>
                                        <StyledListItem>
                                            <img src={getStaticUrl("/images/dialogs/check-mark.svg")} />
                                            <p>Configure slides from one, easy to navigate panel</p>
                                        </StyledListItem>
                                        <StyledListItem>
                                            <img src={getStaticUrl("/images/dialogs/check-mark.svg")} />
                                            <p>Packed with expanded slide functionality and features</p>
                                        </StyledListItem>
                                        <StyledListItem>
                                            <img src={getStaticUrl("/images/dialogs/check-mark.svg")} />
                                            <p>New Smart Slides</p>
                                        </StyledListItem>
                                    </StyledList>
                                </FlexBox>
                            </Section>
                        </>}
                        <Gap20 />
                        <TeamMembersList
                            isValidSubscription={this.isValidSubscription}
                            organizationId={this.props.organizationId}
                            org={org}
                            team={team}
                            getTeam={this.getTeam}
                            handleRemoveMember={handleRemoveMember}
                            getFreshState={this.getFreshState}
                            totalSeatCount={this.orgTotalSeatCount}
                            isDefaultTeam={isDefaultTeam}
                            canManageTeam={canManageTeam}
                            paneClicked={paneClicked}
                            scrollContainerEl={this.contentsContainerRef.current}
                            canAddSeats={paymentMethod || hasAvailableSeats}
                        />
                    </Loadable>
                </UIPaneContents>
            </UIPane>
        );
    }
}

export default ManageTeamPane;
